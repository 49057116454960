import ApolloClient, { gql, InMemoryCache } from "apollo-boost";

export const client = new ApolloClient({
  uri: "https://zondaportal-back-zondaportal-dev.ext.ocp.dev.lafargeholcim-go.com/admin/api",
  cache: new InMemoryCache(),
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.error(`graphQLErrors ${graphQLErrors}`);
    }
    if (networkError) {
      console.error(`network ${networkError}`);
      //logoutUser();
    }
  },
});
// export const getTotalCount = gql`
// query {
//     countretiradas @inprmysql {
//         Products
//     }
// }
// `
export const getTotalCount = gql`
  query GetApps {
    allApplications {
      name
      id
      category {
        name
      }
    }
  }
`;
export const getAllAppsByRole = gql`
  query getAllAppsByRole($order: [SortApplicationsBy!], $functions: [String]) {
    allApplications(
      where: {
        OR: [{ function_some: { name_in: $functions } }, { isGlobal: true }]
      }
      sortBy: $order
    ) {
      name
      id
      description
      link
      category {
        name
      }
    }
  }
`;

export const getAllApps = gql`
  query GetAppsOrdered($search: String = "", $order: [SortApplicationsBy!]) {
    allApplications(
      where: {
        OR: [
          { name_contains: $search }
          { description_contains: $search }
          { category_some: { name_contains_i: $search } }
        ]
      }
      sortBy: $order
    ) {
      name
      id
      description
      link
      category {
        name
      }
    }
  }
`;

export const getUserApps = gql`
  query GetUserApps(
    $id: ID!
    $search: String = ""
    $order: [SortApplicationsBy!]
  ) {
    User(where: { id: $id }) {
      name
      email
      lastOnline
      applications(search: $search, sortBy: $order) {
        id
        name
        link
        description
        category {
          name
        }
      }
    }
  }
`;
export const signIn = gql`
  mutation signin($identity: String, $secret: String) {
    authenticate: authenticateUserWithPassword(
      email: $identity
      password: $secret
    ) {
      token
      item {
        id
        name
        email
        lastOnline
      }
    }
  }
`;

export const addApplication = gql`
  mutation addApplication(
    $identity: ID!
    $applications: [ApplicationWhereUniqueInput]
  ) {
    updateUser(
      id: $identity
      data: { applications: { disconnectAll: true, connect: $applications } }
    ) {
      id
      applications {
        id
      }
    }
  }
`;

export const getAllNews = gql`
  query GetAppsOrdered($order: [SortPostsBy!]) {
    allPosts(sortBy: $order) {
      title
      description
      link
      category {
        name
      }
      type
      CreatedAt
    }
  }
`;
export const updateUserAccess = gql`
  mutation UpdateUserAccess($id: ID!, $access: DateTime) {
    updateUser(id: $id, data: { lastOnline: $access }) {
      id
    }
  }
`;

export const registerNewUser = gql`
  mutation registerNewUser($id: String!, $secret: String!, $name: String!) {
    createUser(
      data: { name: $name, email: $id, isAdmin: false, password: $secret }
    ) {
      id
    }
  }
`;
